import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Rating from '@material-ui/lab/Rating'
import Box from '@material-ui/core/Box'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  secondary: {
    color: theme.palette.text.secondary
  },
  title: {
    padding: '16px'
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
    '& [class*="MuiBox-root"]': {
      marginLeft: '6px',
      fontSize: '12px'
    }
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto',
      display: 'block'
    }
  },
  image: {
    '& :first-child': {
      maxWidth: '100%',
      height: '100%'
    }
  }
}))

export interface Props {
  /**
   * What image to use. Can be a string or a React Node.
   */
  image?: string | React.ReactNode
  /**
   * What image size to use in percents (default 100).
   */
  imageSize?: number
  /**
   * What title to use.
   */
  title?: string
  /**
   * What description to use.
   */
  description?: string
  /**
   * What text to use for the price.
   */
  price?: string
  /**
   * Hightlight color of the rating and price
   */
  hightlightColor?: string
  /**
   * Rating value between 0 - 5. If rating value is undefined, rating will not be visible.
   */
  ratingValue?: 0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5 | number
  /**
   * Rating percision 0.5 - 1, default is 1.
   */
  ratingPrecision?: 0.5 | 1
  /**
   * What text to use for total given rate / feedback.
   */
  totalRatings?: number
  /**
   * Callback fired when the value changes.
   *
   * @param {number} value The new value.
   */
  onChange?: (value: number) => void
  /**
   * The content of the ProductView.
   */
  children?: NonNullable<React.ReactNode>
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const ProductView: React.FC<Props> = ({
  image,
  imageSize = 100,
  title,
  description,
  price,
  hightlightColor,
  ratingValue,
  ratingPrecision = 1,
  totalRatings,
  onChange,
  children,
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [hover, setHover] = React.useState(-1)

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      {image && typeof image === 'string' ? (
        <div className={classes.imageContainer}>
          <img
            style={{
              maxWidth: imageSize ? `${imageSize}%` : '100%'
            }}
            src={image as string}
            alt=""
          />
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className={classes.image}
            style={{
              maxWidth: imageSize ? `${imageSize}%` : '100%'
            }}
          >
            {image}
          </div>
        </div>
      )}
      <div style={image ? { padding: '16px' } : { padding: '8px 16px' }}>
        {title && (
          <Typography
            variant="h6"
            style={{ color: theme.palette.text.primary }}
          >
            {title}
          </Typography>
        )}
        {ratingValue !== undefined ? (
          <div className={classes.rating}>
            <Rating
              name="size-small"
              size="small"
              value={ratingValue}
              precision={ratingPrecision}
              style={{
                color: hightlightColor
                  ? hightlightColor
                  : theme.palette.primary.main
              }}
              emptyIcon={
                <StarBorderIcon
                  fontSize="inherit"
                  style={{
                    color: hightlightColor
                      ? hightlightColor
                      : theme.palette.primary.main
                  }}
                />
              }
              onChange={(_event, newValue) => {
                if (onChange) {
                  onChange(newValue as number)
                }
              }}
              onChangeActive={(_event, newHover) => {
                setHover(newHover)
              }}
            />
            <Box ml={2} className={classes.secondary}>
              {hover !== -1 ? hover : ratingValue !== 0 ? ratingValue : null}
              {totalRatings && ` (${totalRatings})`}
            </Box>
          </div>
        ) : null}
        {description && (
          <Typography variant="body2" className={classes.secondary}>
            {description}
          </Typography>
        )}
        {price && (
          <Typography
            variant="subtitle2"
            style={{
              color: hightlightColor
                ? hightlightColor
                : theme.palette.primary.main
            }}
          >
            {price}
          </Typography>
        )}
      </div>
      {children && children}
    </Wrapper>
  )
}

export default ProductView
