import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Wrapper from '../common/Wrapper'
import Heading from '../Heading/Heading'
import { Typography, Link } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2)
  },
  actionComponents: {
    marginTop: theme.spacing(1)
  },
  helperText: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    marginBottom: theme.spacing(6)
  }
}))

export interface SelectOption {
  value: string | number
  label: string | number
}

export interface Props {
  /**
   * Called when sign in instead is clicked
   */
  onClickHelper?: Function
  /**
   * What headline to use.
   */
  headline?: string
  /**
   * What subtitle to use.
   */
  subtitle?: string
  /**
   * What helperText to use, shown at the bottom.
   */
  helperText?: string
  /**
   * An array of action components, for example a button
   */
  actionComponents?: React.ReactNode[]
  /**
   * An array of content components, for example a input
   */
  contentComponents?: React.ReactNode[]
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const Login: React.FC<Props> = ({
  onClickHelper,
  helperText,
  headline,
  subtitle,
  actionComponents = [],
  contentComponents = [],
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <Heading
        className={classes.heading}
        align="center"
        headline={headline}
        subtitle={subtitle}
      />
      {contentComponents
        ? contentComponents.map((content, index) => {
            return <div key={index}>{content}</div>
          })
        : null}
      {actionComponents
        ? actionComponents.map((button, index) => {
            return (
              <div key={index} className={classes.actionComponents}>
                {button}
              </div>
            )
          })
        : null}
      {onClickHelper ? (
        <Typography
          variant="caption"
          align="center"
          className={classes.helperText}
        >
          <Link
            onClick={() => onClickHelper()}
            color="inherit"
            underline="always"
            component="button"
          >
            {helperText}
          </Link>
        </Typography>
      ) : null}
    </Wrapper>
  )
}

export default Login
