import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Icons from '../common/Icons'
import Wrapper from '../common/Wrapper'
import { Add, Remove, Delete } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  selectedPrimary: {
    backgroundColor: `${theme.palette.primary.main}33` // 8-digit hexadecimal notation.
  },
  listItem: {
    paddingRight: '84px'
  },
  listItemImageRectangle: {
    paddingLeft: '0px'
  },
  imageBorder: {
    border: '1px solid rgba(0, 44, 56, 0.12)'
  },
  imageContainer: {
    height: '56px',
    width: '56px',
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto',
      display: 'block'
    }
  },
  imageRectangle: {
    width: '100px',
    display: 'flex'
  },
  icon: {
    color: theme.palette.text.primary
  },
  primary: {
    color: theme.palette.text.primary
  },
  secondary: {
    display: 'block',
    color: theme.palette.text.secondary
  },
  addIconRightPosition: {
    right: '25px'
  },
  amountOpenRightPosition: {
    right: '14px'
  },
  disableGuttersNotOpenAmount: {
    right: '14px'
  },
  disableGuttersOpenAmount: {
    right: '3px'
  },
  disableGuttersNotOpenAmountHasQuantity: {
    right: '3px'
  },
  disableGutterSingle: {
    right: '14px'
  },
  quantityText: {
    color: theme.palette.text.primary,
    marginRight: '1px'
  },
  notActiveAmountContainer: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '100px',
    width: '66px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  amountContainer: {
    backgroundColor: theme.palette.common.white,
    width: '127px',
    height: '32px',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: '100px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  amountInput: {
    height: '32px',
    width: '32px',
    border: 'none',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.text.primary
  },
  amountText: {
    color: theme.palette.text.secondary
  },
  amountIcon: {
    padding: '6px',
    height: '32px',
    width: '32px'
  },
  amountIconAdd: {
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.common.white
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  amountIconRemove: {
    backgroundColor: theme.palette.common.white,
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  deleteIcon: {
    backgroundColor: theme.palette.common.white,
    height: '32px',
    width: '32px',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    '& svg': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  iconButton: {
    '& svg': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  addIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    height: '32px',
    width: '32px'
  },
  removeIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    height: '32px',
    width: '32px',
    '& svg': {
      color: theme.palette.common.white
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))

export interface Props {
  /**
   * What catalog item variant to use
   */
  variant?: 'single' | 'multi'
  /**
   * If `true`, the component background color is changed indicating that it is selected.
   */
  selected: boolean
  /**
   * The text content.
   */
  text?: string
  /**
   * Array of strings. Subtitles are shown as rows below the text.
   */
  subtitles?: string[]
  /**
   *  Icon uses Material UI icon names.
   */
  icon?: string
  /**
   * Image's url
   */
  image?: string
  /**
   * Adds border around the image
   */
  imageBorder?: boolean
  /**
   * If 'true', image area is a rectangle instead of a square
   */
  imageRectangle?: boolean
  /**
   * Quantity of the item
   */
  quantity?: number
  /**
   * Id of the item
   */
  itemId?: string | number
  /**
   * Dispatches action object with type and payload properties. Payload object has an id and optional value -properties.
   */
  dispatch?: (action: {
    type: string
    payload: {
      id: string | number
      value?: string | number
    }
  }) => void
  /**
   * Minimum possible quantity
   */
  minQuantity?: number
  /**
   * Quantity unit type
   */
  unit?: string
  /**
   * Component's onClick event
   */
  onClick?: React.MouseEventHandler
  /**
   * If 'true, the left and right padding is removed
   */
  disableGutters?: boolean
  /**
   * If 'true', compact vertical padding designed for keyboard and mouse input will be used.
   */
  dense?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const CatalogItem: React.FC<Props> = ({
  variant = 'multi',
  selected = false,
  text,
  subtitles = [],
  icon = '',
  image,
  imageBorder = false,
  imageRectangle = false,
  quantity = 0,
  itemId,
  dispatch,
  minQuantity = 0,
  unit = 'kpl',
  onClick,
  disableGutters = false,
  dense = false,
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()
  const [openAmount, setOpenAmount] = useState<boolean>(false)

  useEffect(() => {
    const timer = setInterval(() => {
      setOpenAmount(false)
    }, 5000)
    return () => {
      clearInterval(timer)
    }
  }, [quantity])

  let iconType: string | undefined = icon
  if (!Object.prototype.hasOwnProperty.call(Icons, icon)) {
    iconType = undefined
  }

  const handleOpenAmount = () => {
    if (quantity === 0) {
      if (dispatch && itemId) {
        dispatch({
          type: 'increase',
          payload: {
            id: itemId
          }
        })
      }
    }
    setOpenAmount(true)
  }

  const removeQuantity = () => {
    if (dispatch && itemId) {
      dispatch({
        type: 'remove',
        payload: {
          id: itemId
        }
      })
    }
    setOpenAmount(false)
  }

  const customQuantity = (value: string) => {
    if (dispatch && itemId) {
      let givenValue = value
      if (givenValue === '') {
        givenValue = String(minQuantity)
      }
      const valueToNumber = Number(givenValue)
      if (Number.isInteger(valueToNumber)) {
        dispatch({
          type: 'setQuantity',
          payload: {
            id: itemId,
            value: valueToNumber
          }
        })
      }
    }
  }

  const handleClickSingle = () => {
    if (dispatch && itemId) {
      if (selected) {
        dispatch({
          type: 'decrease',
          payload: {
            id: itemId
          }
        })
      } else {
        dispatch({
          type: 'increase',
          payload: {
            id: itemId
          }
        })
      }
    }
  }

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <div
        className={clsx({
          [classes.selectedPrimary]: selected
        })}
        onClick={onClick}
      >
        <List
          onClick={variant === 'single' ? handleClickSingle : undefined}
          disablePadding
          dense={dense}
          style={{ width: '100%', cursor: 'pointer' }}
        >
          <ListItem
            onClick={variant === 'multi' ? handleOpenAmount : undefined}
            className={clsx(classes.listItem, {
              [classes.listItemImageRectangle]: imageRectangle && image
            })}
            disableGutters={disableGutters}
            alignItems={
              iconType && !image
                ? 'center'
                : subtitles.length <= 1
                ? 'center'
                : 'flex-start'
            }
          >
            {image ? (
              <div style={{ minWidth: imageRectangle ? '116px' : '72px' }}>
                <ListItemAvatar
                  style={{ cursor: 'pointer' }}
                  className={clsx(classes.imageContainer, {
                    [classes.imageRectangle]: imageRectangle,
                    [classes.imageBorder]: imageBorder
                  })}
                >
                  <img src={image} alt="product" />
                </ListItemAvatar>
              </div>
            ) : iconType ? (
              <div
                style={{
                  height: subtitles.length <= 1 ? '23px' : '',
                  cursor: 'pointer'
                }}
              >
                <ListItemIcon className={classes.icon}>
                  {React.createElement(Icons[iconType])}
                </ListItemIcon>
              </div>
            ) : null}
            <ListItemText
              primary={
                <React.Fragment>
                  {text ? (
                    <Typography variant="subtitle1" className={classes.primary}>
                      {text}
                    </Typography>
                  ) : null}
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  {subtitles.map((subtitle, i) => (
                    <Typography
                      key={i}
                      className={classes.secondary}
                      variant="body2"
                      component="span"
                    >
                      {subtitle}
                    </Typography>
                  ))}
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction
              onClick={onClick}
              className={clsx({
                [classes.addIconRightPosition]: variant === 'single'
              })}
              classes={{
                root: clsx({
                  [classes.disableGutterSingle]:
                    disableGutters && variant === 'single',
                  [classes.addIconRightPosition]:
                    variant === 'multi' && !openAmount && quantity <= 0,
                  [classes.disableGuttersNotOpenAmount]:
                    variant === 'multi' &&
                    !openAmount &&
                    quantity <= 0 &&
                    disableGutters,
                  [classes.amountOpenRightPosition]:
                    variant === 'multi' && openAmount,
                  [classes.disableGuttersOpenAmount]:
                    variant === 'multi' && openAmount && disableGutters,
                  [classes.disableGuttersNotOpenAmountHasQuantity]:
                    variant === 'multi' &&
                    !openAmount &&
                    quantity > 0 &&
                    disableGutters
                })
              }}
            >
              {variant === 'single' ? (
                <React.Fragment>
                  {!selected ? (
                    <IconButton
                      className={clsx(classes.iconButton, classes.addIcon)}
                      disableRipple
                      edge="end"
                    >
                      <Add />
                    </IconButton>
                  ) : (
                    <IconButton
                      className={classes.removeIcon}
                      disableRipple
                      edge="end"
                    >
                      <Remove />
                    </IconButton>
                  )}
                </React.Fragment>
              ) : variant === 'multi' ? (
                <React.Fragment>
                  {!openAmount ? (
                    quantity <= 0 ? (
                      <IconButton
                        className={clsx(classes.iconButton, classes.addIcon)}
                        disableRipple
                        edge="end"
                        onClick={handleOpenAmount}
                      >
                        <Add />
                      </IconButton>
                    ) : (
                      <div
                        onClick={handleOpenAmount}
                        className={classes.notActiveAmountContainer}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            className={classes.quantityText}
                            variant="body2"
                          >
                            {quantity}
                          </Typography>
                          <Typography
                            className={classes.amountText}
                            variant="caption"
                          >
                            {unit}
                          </Typography>
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '170px'
                      }}
                    >
                      <IconButton
                        className={classes.deleteIcon}
                        disableRipple
                        onClick={removeQuantity}
                      >
                        <Delete />
                      </IconButton>
                      <div className={classes.amountContainer}>
                        <IconButton
                          classes={{ root: classes.amountIcon }}
                          className={classes.amountIconRemove}
                          disableRipple
                          onClick={() => {
                            if (quantity > minQuantity && dispatch && itemId) {
                              dispatch({
                                type: 'decrease',
                                payload: {
                                  id: itemId
                                }
                              })
                            }
                          }}
                        >
                          <Remove />
                        </IconButton>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className={classes.amountInput}
                            type="tel"
                            value={quantity}
                            onChange={(e) => customQuantity(e.target.value)}
                          />
                          <Typography
                            className={classes.amountText}
                            variant="caption"
                          >
                            {unit}
                          </Typography>
                        </div>
                        <IconButton
                          classes={{ root: classes.amountIcon }}
                          className={classes.amountIconAdd}
                          disableRipple
                          onClick={() => {
                            if (dispatch && itemId) {
                              dispatch({
                                type: 'increase',
                                payload: {
                                  id: itemId
                                }
                              })
                            }
                          }}
                        >
                          <Add />
                        </IconButton>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ) : null}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    </Wrapper>
  )
}

export default CatalogItem
