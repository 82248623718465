import React, { memo, useState, useCallback, CSSProperties } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import SingleInput from './SingleInput'
import Button from '../Button/Button'
import Container from '../Container/Container'
import IconButton from '@material-ui/core/IconButton'
import { Typography } from '@material-ui/core'
import BackspaceIcon from '@material-ui/icons/Backspace'

const useStyles = makeStyles((theme) => ({
  numPad: {
    flexBasis: '33%'
  },
  numPads: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '24px',
    padding: '0px 16px'
  },
  errorMessage: {
    color: theme.palette.error.main
  },
  button: {
    '& > div > button': {
      fontSize: '30px !important',
      color: theme.palette.text.primary
    }
  }
}))

export interface OTPInputProps {
  length: number
  onSubmit: (otp: string) => any
  showPassword?: boolean
  showNumpad?: boolean
  errorText?: string

  autoFocus?: boolean
  isNumberInput?: boolean

  style?: CSSProperties
  className?: string

  inputStyle?: CSSProperties
  inputClassName?: string
}

const OTPInputComponent: React.FC<OTPInputProps> = ({
  length,
  isNumberInput,
  autoFocus,
  onSubmit,
  showPassword = true,
  showNumpad = true,
  errorText = '',
  inputClassName,
  inputStyle,
  ...rest
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const [activeInput, setActiveInput] = useState(0)
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''))
  const numPads = [
    <Button
      className={classes.button}
      onClick={() => handleOnChange('1')}
      variant="text"
      label="1"
    />,
    <Button
      className={classes.button}
      onClick={() => handleOnChange('2')}
      variant="text"
      label="2"
    />,
    <Button
      className={classes.button}
      onClick={() => handleOnChange('3')}
      variant="text"
      label="3"
    />,
    <Button
      className={classes.button}
      onClick={() => handleOnChange('4')}
      variant="text"
      label="4"
    />,
    <Button
      className={classes.button}
      onClick={() => handleOnChange('5')}
      variant="text"
      label="5"
    />,
    <Button
      className={classes.button}
      onClick={() => handleOnChange('6')}
      variant="text"
      label="6"
    />,
    <Button
      className={classes.button}
      onClick={() => handleOnChange('7')}
      variant="text"
      label="7"
    />,
    <Button
      className={classes.button}
      onClick={() => handleOnChange('8')}
      variant="text"
      label="8"
    />,
    <Button
      className={classes.button}
      onClick={() => handleOnChange('9')}
      variant="text"
      label="9"
    />,
    <Button style={{ visibility: 'hidden' }} variant="text" label="" />,
    <Button
      className={classes.button}
      onClick={() => handleOnChange('0')}
      variant="text"
      label="0"
    />,
    <IconButton
      style={{ marginTop: 5, color: theme.palette.text.primary }}
      onClick={() => removeValue()}
    >
      <BackspaceIcon />
    </IconButton>
  ]

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join('')
      if (otpValue.length === otpValues.length) {
        onSubmit(otpValue)
      }
    },
    [onSubmit, otpValues.length]
  )

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues]
      updatedOTPValues[activeInput] = str[0] || ''
      setOTPValues(updatedOTPValues)
      handleOtpChange(updatedOTPValues)
    },
    [activeInput, handleOtpChange, otpValues]
  )

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0)
      setActiveInput(selectedIndex)
    },
    [length]
  )

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1)
  }, [activeInput, focusInput])

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1)
  }, [activeInput, focusInput])

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index)
    },
    [focusInput]
  )

  const getRightValue = useCallback(
    (str: string) => {
      let changedValue = str
      if (!isNumberInput) {
        return changedValue
      }
      return !changedValue || /\d/.test(changedValue) ? changedValue : ''
    },
    [isNumberInput]
  )

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (event) => {
      const value = getRightValue(event)
      if (!value) {
        event.preventDefault()
        return
      }
      changeCodeAtFocus(value)
      focusNextInput()
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  )

  const removeValue = useCallback(() => {
    if (otpValues[activeInput]) {
      changeCodeAtFocus('')
    }
    focusPrevInput()
  }, [activeInput, otpValues, changeCodeAtFocus, focusPrevInput])

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault()
          if (otpValues[activeInput]) {
            changeCodeAtFocus('')
          } else {
            focusPrevInput()
          }
          break
        }
        case 'ArrowLeft': {
          e.preventDefault()
          focusPrevInput()
          break
        }
        case 'ArrowRight': {
          e.preventDefault()
          focusNextInput()
          break
        }
        case ' ': {
          e.preventDefault()
          break
        }
        default:
          break
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  )

  return (
    <div {...rest}>
      {Array(length)
        .fill('')
        .map((_, index) => (
          <SingleInput
            key={`SingleInput-${index}`}
            focus={activeInput === index}
            value={otpValues && otpValues[index]}
            autoFocus={autoFocus}
            onFocus={handleOnFocus(index)}
            onChange={(e) => handleOnChange(e.target.value)}
            onKeyDown={handleOnKeyDown}
            style={inputStyle}
            className={inputClassName}
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
          />
        ))}
      <Typography
        align="center"
        className={classes.errorMessage}
        style={{ visibility: errorText ? 'visible' : 'hidden' }}
      >
        {errorText || 'Error'}
      </Typography>
      {showNumpad ? (
        <Container maxWidth="xs" className={classes.numPads}>
          {numPads.map((numPad, index) => (
            <div key={index} className={classes.numPad}>
              {numPad}
            </div>
          ))}
        </Container>
      ) : null}
    </div>
  )
}

const OTPInput = memo(OTPInputComponent)
export default OTPInput
