import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Popover from '@material-ui/core/Popover'
import Wrapper from '../common/Wrapper'
import ListItemText from '@material-ui/core/ListItemText'
import Fab from '@material-ui/core/Fab'
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import { ReactComponent as Emoji1 } from '../../assets/emojis/1.svg'
import { ReactComponent as Emoji2 } from '../../assets/emojis/2.svg'
import { ReactComponent as Emoji3 } from '../../assets/emojis/3.svg'
import { ReactComponent as Emoji4 } from '../../assets/emojis/4.svg'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
  direction: 'rtl'
})

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.text.primary
  },
  secondary: {
    color: theme.palette.text.secondary
  },
  dialog: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left',
    height: 72,
    width: 288,
    '& [class*="MuiListItemText-root"]': {
      flex: 'none'
    }
  },
  text: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 72,
    paddingBottom: '18px',
    boxSizing: 'border-box'
  },
  paper: {
    display: 'inline-block',
    boxSizing: 'border-box',
    borderRadius: 100,
    height: 72,
    width: 288,
    textAlign: 'center',
    padding: '8px 0px 0px 0px',
    '& [class*="MuiFab-root"]': {
      boxShadow: 'none'
    },
    '& [class*="MuiFab-root"][class*="MuiFab-colorInherit"]': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.primary.main}`
    },
    '& [class*="MuiFab-root"][class*="MuiFab-colorInherit"]:hover': {
      backgroundColor: 'transparent',
      opacity: '0.7'
    },
    '& [class*="MuiRating-root"][class*="Mui-disabled"]': {
      opacity: '1 !important'
    }
  },
  rating: {
    boxSizing: 'border-box',
    '& [class*="MuiRating-icon"] .selected svg': {
      boxShadow:
        '0px 2px 1px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: 100
    },
    '& [class*="MuiRating-icon"] .not-selected svg path:first-child': {
      fill: '#CCD4D7'
    },
    '& [class*="MuiRating-icon"] .hovering': {
      opacity: 0.5
    },
    '& [class*="MuiRating-iconActive"]': {
      transform: 'scale(1)',
      color: 'inherit'
    }
  },
  popover: {
    pointerEvents: 'none',
    '& [class*="MuiPopover-paper"]': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '20px',
      height: 'auto',
      width: 'auto'
    }
  }
}))

export interface Props {
  /**
   * The component name.
   */
  name?: string
  /**
   * The message text to be shown after the feedback is submitted.
   */
  text?: string
  /**
   * The title for the dialog, (limited to 20 letters, including the space).
   */
  title?: string
  /**
   * The subtitle for the dialog,
   * if longer than 22 letters, the ellipsis (i.e. three little dots) will be enabled
   * and you can view the full text version by hovering over it.
   */
  subtitle?: string
  /**
   * Returns the value of the feedback.
   */
  onChange?: any
  /**
   * Set the value of the feedback.
   */
  feedbackValue?: 1 | 2 | 3 | 4
  /**
   * If the comment is submitted.
   * Note: this requires the "feedbackValue" props to be set first.
   */
  commentSubmitted?: boolean
  /**
   * Confirm button's onClicked event
   */
  onConfirm?: React.MouseEventHandler
  /**
   * Cancel button's onClicked event
   */
  onCancel?: React.MouseEventHandler
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
}

const Feedback: React.FC<Props> = ({
  name = 'Feedback',
  text,
  title = 'Thanks',
  subtitle = 'Add comments?',
  onChange,
  onConfirm,
  onCancel,
  feedbackValue,
  commentSubmitted = false,
  style,
  className
}) => {
  const classes = useStyles()

  const customIcons = [
    {
      icon: <Emoji4 id="very-happy" style={{ width: 56, height: 56 }} />,
      index: 4,
      value: 'very-happy'
    },
    {
      icon: <Emoji3 id="happy" style={{ width: 56, height: 56 }} />,
      index: 3,
      value: 'happy'
    },
    {
      icon: <Emoji2 id="unhappy" style={{ width: 56, height: 56 }} />,
      index: 2,
      value: 'unhappy'
    },
    {
      icon: <Emoji1 id="very-unhappy" style={{ width: 56, height: 56 }} />,
      index: 1,
      value: 'very-unhappy'
    }
  ]

  const [clicked, setClicked] = useState(false)
  const [hovering, setHovering] = useState(-1)
  const [currentValue, setCurrentValue] = useState(0)

  const handleClick = (event: any) => {
    if (event.target.value) {
      if (onChange) {
        onChange(event.target.value)
      }
    }
  }

  /**
   * For the popover control
   */
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const popoverOpen = Boolean(anchorEl)

  return (
    <Wrapper style={style} className={className}>
      <Paper className={classes.paper}>
        {clicked || (feedbackValue && (text || onConfirm)) ? (
          onConfirm ? (
            commentSubmitted === false || !feedbackValue ? (
              <div className={classes.dialog}>
                <ListItemText
                  className={classes.text}
                  style={{ marginLeft: '16px' }}
                  primary={
                    <Typography variant="subtitle1" className={classes.primary}>
                      {title.length > 20 ? `${title.substring(0, 20)}` : title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      className={classes.secondary}
                      variant="body2"
                      component="span"
                    >
                      {subtitle.length > 21 ? (
                        <span
                          aria-owns={
                            popoverOpen ? 'mouse-over-popover' : undefined
                          }
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        >
                          {subtitle.substring(0, 19)} ...
                        </span>
                      ) : (
                        subtitle
                      )}
                    </Typography>
                  }
                />
                <Fab
                  color="primary"
                  aria-label="add"
                  className="inline"
                  style={{ margin: '0 0 2px 6px' }}
                  onClick={onConfirm}
                >
                  <CheckIcon />
                </Fab>
                <Fab
                  color="inherit"
                  aria-label="delete"
                  className="inline"
                  style={{ margin: '0px 8px 0px 6px' }}
                  onClick={onCancel}
                >
                  <ClearIcon />
                </Fab>
              </div>
            ) : (
              <div className={classes.text}>
                <Typography variant="subtitle1" className={classes.primary}>
                  {text ? text : 'Thank you for your feedback!'}
                </Typography>
              </div>
            )
          ) : text ? (
            <div className={classes.text}>
              <Typography variant="subtitle1" className={classes.primary}>
                {text}
              </Typography>
            </div>
          ) : null
        ) : (
          <ThemeProvider theme={theme}>
            <Rating
              dir="rtl"
              className={classes.rating}
              max={4}
              name={name}
              value={currentValue}
              disabled={currentValue > 0 || feedbackValue !== undefined}
              onChangeActive={(event, newValue) => {
                setHovering(newValue)
              }}
              onChange={(event, newValue) => {
                setCurrentValue(newValue! || 0)
                if (text || onConfirm) {
                  setClicked(true)
                }
              }}
              onClick={handleClick}
              IconContainerComponent={(props: { value: number }) => {
                const { value, ...other } = props
                const iconClasses = []
                if (value === currentValue || feedbackValue! === value) {
                  iconClasses.push('selected')
                } else if (
                  (value !== currentValue && currentValue > 0) ||
                  (feedbackValue! > 0 && feedbackValue !== currentValue)
                ) {
                  iconClasses.push('not-selected')
                }
                if (hovering > 0 && value !== hovering) {
                  iconClasses.push('hovering')
                }
                return (
                  <span {...other}>
                    <span
                      className={iconClasses.join(' ')}
                      style={{ margin: '0 7px' }}
                    >
                      {customIcons.find((item) => item.index === value)!.icon}
                    </span>
                  </span>
                )
              }}
            />
          </ThemeProvider>
        )}
      </Paper>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>{subtitle}</div>
      </Popover>
    </Wrapper>
  )
}

export default Feedback
