import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '18px 8px 2px 8px',
    margin: 'auto',
    maxWidth: 344,
    '@media (min-width: 1024px)': {
      maxWidth: 400
    }
  },
  centerText: {
    textAlign: 'center'
  },
  text: {
    color: theme.palette.text.secondary
  }
}))

export interface Props {
  /**
   * What text to use
   */
  text?: string
  /**
   * If 'true', text is centered
   */
  centerText?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const FeedTitle: React.FC<Props> = ({
  text,
  centerText = false,
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <div
        className={clsx(classes.container, {
          [classes.centerText]: centerText
        })}
      >
        <Typography className={classes.text} variant="subtitle1">
          {text}
        </Typography>
      </div>
    </Wrapper>
  )
}

export default FeedTitle
