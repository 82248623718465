import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { default as MaterialAppBar } from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Icons from '../common/Icons'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#FFFFFF'
  },
  appBarTransparent: {
    backgroundColor: 'transparent'
  },
  appbarVisible: {
    transition: '150ms'
  },
  appbarHidden: {
    transition: '150ms',
    transform: 'translateY(-56px)'
  },
  toolbar: {
    minHeight: '56px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'block'
  },
  insideWrapper: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    minHeight: '56px'
  },
  border: {
    borderBottom: '1px solid rgba(0, 44, 56, 0.12)'
  },
  mainAction: {
    marginRight: theme.spacing(2)
  },
  text: {
    flexGrow: 1
  },
  centerText: {
    textAlign: 'center'
  }
}))

export interface AppBarAction {
  icon: string
  onClick?: React.MouseEventHandler
}

export interface Props {
  /**
   * Hides component until value in pixels is scrolled down.
   */
  showScrollHeight?: number
  /**
   * What position to use.
   */
  position?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky'
  /**
   * Shows bottom border.
   */
  border?: boolean
  /**
   * What logo to use. Can be a string or a React Node.
   */
  logo?: string | React.ReactNode
  /**
   * Logo's size in pixels.
   */
  logoSize?: number
  /**
   * AppBar's leftmost action, an object with icon and onClick properties. Icon uses Material UI icon names.
   */
  mainAction?: AppBarAction
  /**
   * What text to use.
   */
  text?: string
  /**
   * Centers text or logo.
   */
  centerText?: boolean
  /**
   * What color for content (text, actions) to use.
   */
  color?: string
  /**
   * What background color to use.
   */
  backgroundColor?: string
  /**
   * AppBar's actions on the right side. An array of objects with icon and onClick properties.
   */
  actions?: AppBarAction[]
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const AppBar: React.FC<Props> = ({
  showScrollHeight,
  position = 'static',
  border = false,
  logo,
  logoSize = 90,
  text,
  centerText = false,
  color,
  backgroundColor = '#FFFFFF',
  mainAction = { icon: '' },
  actions = [],
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()
  const [visible, setVisible] = useState<boolean>(true)
  const theme = useTheme()
  const contentColor = color || theme.palette.text.primary

  useEffect(() => {
    if (showScrollHeight) {
      const handleScroll = () => {
        const show = window.scrollY > showScrollHeight
        show ? setVisible(true) : setVisible(false)
      }
      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }
    return setVisible(true)
  }, [showScrollHeight])

  let iconType: string | undefined = mainAction.icon
  if (!Object.prototype.hasOwnProperty.call(Icons, mainAction.icon)) {
    iconType = undefined
  }

  return (
    <Wrapper style={style} className={className}>
      <MaterialAppBar
        elevation={0}
        position={position}
        classes={{
          root: clsx(
            backgroundColor === 'transparent'
              ? classes.appBarTransparent
              : classes.appBar
          )
        }}
        className={clsx({
          [classes.appbarHidden]: !visible,
          [classes.appbarVisible]: visible,
          [classes.border]: border
        })}
      >
        <Toolbar
          classes={{ root: classes.toolbar }}
          style={{
            backgroundColor: backgroundColor ? backgroundColor : 'white'
          }}
        >
          <Wrapper className={classes.insideWrapper} maxWidth={maxWidth}>
            {iconType ? (
              <IconButton
                edge="start"
                className={classes.mainAction}
                onClick={mainAction.onClick}
                style={{ color: contentColor }}
              >
                {React.createElement(Icons[iconType])}
              </IconButton>
            ) : null}
            {logo ? (
              typeof logo === 'string' || logo instanceof String ? (
                <div
                  className={clsx(classes.text, {
                    [classes.centerText]: centerText
                  })}
                >
                  <img
                    src={logo as string}
                    style={{ width: `${logoSize}px` }}
                    alt="logo"
                  />
                </div>
              ) : (
                <div style={{ width: `${logoSize}px` }}>{logo}</div>
              )
            ) : (
              <Typography
                className={clsx(classes.text, {
                  [classes.centerText]: centerText
                })}
                variant="subtitle1"
                style={{ color: contentColor }}
              >
                {text}
              </Typography>
            )}
            {actions.map((action, i) => {
              let icon: string | undefined = action.icon
              if (!Object.prototype.hasOwnProperty.call(Icons, action.icon)) {
                icon = undefined
              }
              return (
                <IconButton
                  key={i}
                  style={{ color: contentColor }}
                  onClick={action.onClick}
                  edge="end"
                >
                  {icon ? React.createElement(Icons[icon]) : null}
                </IconButton>
              )
            })}
          </Wrapper>
        </Toolbar>
      </MaterialAppBar>
    </Wrapper>
  )
}

export default AppBar
