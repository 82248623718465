import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { default as MaterialButton } from '@material-ui/core/Button'
import Icons from '../common/Icons'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '8px 16px 8px 16px'
  },
  disablePadding: {
    padding: '0'
  },
  radioCheckboxContainer: {
    padding: '4px 16px'
  },
  button: {
    padding: '0 26px',
    height: '48px'
  },
  buttonFullWidth: {
    width: '100%'
  },
  buttonLabel: {
    fontStyle: theme.typography.button.fontStyle,
    fontWeight: theme.typography.button.fontWeight,
    fontSize: theme.typography.button.fontSize,
    lineHeight: theme.typography.button.lineHeight,
    letterSpacing: theme.typography.button.letterSpacing,
    textTransform: 'none'
  },
  underlineText: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  buttonOutlined: {
    backgroundColor: theme.palette.common.white,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      border: 'none'
    }
  },
  outlinedShadow: {
    backgroundColor: theme.palette.common.white,
    border: 'none',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      border: 'none'
    }
  },
  buttonText: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  metaText: {
    display: 'flex',
    alignSelf: 'center'
  },
  primaryDisabled: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    opacity: '0.38'
  },
  secondaryDisabled: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    opacity: '0.38'
  },
  outlinedNoBorderDisabled: {
    border: 'none !important'
  },
  buttonContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

type Variant = 'contained' | 'outlined' | 'text'
export interface ButtonCustomColor {
  color?: string
  backgroundColor?: string
}

export interface Props {
  /**
   * The value of the component
   */
  value?: string | number
  /**
   *  Icon uses Material UI icon names.
   */
  icon?: string
  /**
   * Button's label
   */
  label?: string | React.ReactNode
  /**
   * Metatext is shown at the right side of a button, pushes label to the left
   */
  metaText?: string
  /**
   * The variant to use.
   */
  variant?: 'contained' | 'default' | 'text'
  /**
   * The color of the component based on the theme that is used
   */
  color?: 'primary' | 'secondary'
  /**
   * An object with color and backgroundColor properties, use these to override the theme palette colors.
   */
  customColor?: ButtonCustomColor
  /**
   * If 'true', displays shadow
   */
  shadow?: boolean
  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth?: boolean
  /**
   * If 'true', the button will be squared
   */
  square?: boolean
  /**
   * If 'true', adds border around the button
   */
  border?: boolean
  /**
   * If 'true', the button will be disabled
   */
  disabled?: boolean
  /**
   * If 'true', the button will be underlined
   */
  underline?: boolean
  /**
   * If 'true, the button's padding will be disabled
   */
  disablePadding?: boolean
  /**
   * If 'true', button's variant will be 'contained' to present its selection
   */
  checked?: boolean
  /**
   * The type of the button
   */
  type?: 'button' | 'reset' | 'submit'
  /**
   * Choosing a specific role adjusts button styling that fits its role
   */
  role?: 'status' | 'radio' | 'checkbox' | ''
  /**
   * Button's onClick event
   */
  onClick?: React.MouseEventHandler
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const Button: React.FC<Props> = ({
  value = '',
  icon = '',
  label,
  metaText,
  variant = 'contained',
  color = 'secondary',
  customColor = { color: '', backgroundColor: '' },
  shadow = false,
  fullWidth = false,
  square = false,
  border = false,
  disabled = false,
  underline = false,
  disablePadding = false,
  checked,
  type = 'button',
  role = '',
  onClick,
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()
  const theme = useTheme()

  let iconType: string | undefined = icon
  if (!Object.prototype.hasOwnProperty.call(Icons, icon)) {
    iconType = undefined
  }
  let borderColor: string = ''
  if (color === 'primary') {
    borderColor = theme.palette.primary.main
  }
  if (color === 'secondary') {
    borderColor = theme.palette.secondary.main
  }
  if (disabled) {
    borderColor = 'rgba(0, 0, 0, 0.26)'
  }

  let variantType: Variant = 'contained'
  if (variant === 'text') {
    variantType = 'text'
  } else if (variant === 'contained') {
    variantType = 'contained'
  } else {
    variantType = 'outlined'
  }

  let radioCheckbox = false

  if (role === 'status') {
    color = 'primary'
    variantType = 'contained'
    square = false
    shadow = true
    fullWidth = false
  }

  if (role === 'radio') {
    color = 'secondary'
    variantType = 'outlined'
    shadow = true
    square = false
    fullWidth = true
    radioCheckbox = true
  }

  if (role === 'checkbox') {
    color = 'secondary'
    variantType = 'outlined'
    shadow = true
    square = true
    fullWidth = true
    radioCheckbox = true
  }

  if (checked) {
    variantType = 'contained'
  }

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <div
        className={clsx({
          [classes.radioCheckboxContainer]: radioCheckbox,
          [classes.disablePadding]: role === 'status' || disablePadding,
          [classes.container]: !disablePadding
        })}
      >
        <MaterialButton
          value={value}
          disableRipple={role === 'radio' || role === 'checkbox'}
          disableFocusRipple
          variant={variantType}
          onClick={onClick}
          startIcon={iconType ? React.createElement(Icons[iconType]) : null}
          style={{
            borderRadius: square ? 0 : '100px',
            border: border ? `1px solid ${borderColor}` : '',
            color: customColor.color,
            backgroundColor: customColor.backgroundColor
          }}
          className={clsx({
            [classes.buttonFullWidth]: fullWidth || metaText
          })}
          classes={{
            root: clsx(classes.button, classes.buttonLabel, {
              [classes.underlineText]: underline,
              [classes.buttonOutlined]: variantType === 'outlined' && !shadow,
              [classes.outlinedShadow]: variantType === 'outlined' && shadow,
              [classes.buttonText]: variantType === 'text'
            }),
            disabled: clsx({
              [classes.primaryDisabled]:
                color === 'primary' && variantType === 'contained',
              [classes.secondaryDisabled]:
                color === 'secondary' && variantType === 'contained',
              [classes.outlinedNoBorderDisabled]:
                variantType === 'outlined' && !border
            })
          }}
          disableElevation={!shadow}
          color={
            color === 'primary' || color === 'secondary' ? color : 'default'
          }
          disabled={disabled}
          type={type}
        >
          {!metaText ? (
            label
          ) : (
            <div className={classes.buttonContent}>
              <Typography variant="subtitle1" component="span">
                {label}
              </Typography>
              <Typography
                variant="caption"
                className={classes.metaText}
                component="span"
              >
                {metaText}
              </Typography>
            </div>
          )}
        </MaterialButton>
      </div>
    </Wrapper>
  )
}

export default Button
