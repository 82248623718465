import React, { Fragment } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { default as MaterialTextField } from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icons from '../common/Icons'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '8px 16px 8px 16px'
  },
  disableGutters: {
    padding: '8px 0px'
  },
  inputText: {
    fontStyle: theme.typography.subtitle1.fontStyle,
    fontWeight: theme.typography.subtitle1.fontWeight,
    fontSize: theme.typography.subtitle1.fontSize,
    lineHeight: theme.typography.subtitle1.lineHeight,
    letterSpacing: theme.typography.subtitle1.letterSpacing
  },
  inputField: {
    '& p': {
      display: 'flex',
      justifyContent: 'space-between',
      fontStyle: theme.typography.caption.fontStyle,
      fontWeight: theme.typography.caption.fontWeight,
      fontSize: theme.typography.caption.fontSize,
      lineHeight: theme.typography.caption.lineHeight,
      letterSpacing: theme.typography.caption.letterSpacing
    }
  },
  inputSuccess: {
    '& [class*="MuiFormLabel-root"]': {
      color: theme.palette.success.main
    },
    '& [class*="MuiOutlinedInput-root"]': {
      '& fieldset': {
        borderColor: theme.palette.success.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.success.main
      },
      '& [class*="Mui-focused"] fieldset': {
        borderColor: theme.palette.success.main
      }
    }
  },
  helperTextSuccess: {
    color: theme.palette.success.main
  }
}))

export interface TextFieldPrefixAndSuffix {
  text?: string
  icon?: string
  onClick?: React.MouseEventHandler
}

export interface Props {
  /**
   * Name attribute of the component.
   */
  name?: string
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   */
  autoComplete?: string
  /**
   * The value of the component, required for a controlled component.
   */
  value?: string
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value`.
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * The label content.
   */
  label?: string
  /**
   * What error text to use. Error text is displayed below the select component.
   */
  errorText?: string
  /**
   * If 'true', the border of the component is displayed in green color
   */
  success?: boolean
  /**
   * The type of the component
   */
  type?: string
  /**
   * The hint displayed in the input before the user enters a value.
   */
  placeholder?: string
  /**
   * The helper text content.
   */
  helperText?: string
  /**
   * Maximum length of the entered text
   */
  maxLength?: number
  /**
   * Prefix is set before the entered text and is an object with text, icon and onClick properties.
   */
  prefix?: TextFieldPrefixAndSuffix
  /**
   * Suffix is set at the end of the text area and is an object with text, icon and onClick properties.
   */
  suffix?: TextFieldPrefixAndSuffix
  /**
   * Number of rows to be displayed.
   */
  rows?: number
  /**
   * If `true`, the label is displayed as required and the `input` element will be required.
   */
  required?: boolean
  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth?: boolean
  /**
   * If 'true', the component will be disabled
   */
  disabled?: boolean
  /**
   * If 'true', the left and right padding is removed
   */
  disableGutters?: boolean
  /**
   * If 'true', the component's padding will be disabled
   */
  disablePadding?: boolean
  /**
   * If `true`, the `input` element will be focused during the first mount.
   */
  autoFocus?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const TextField: React.FC<Props> = ({
  name,
  autoComplete,
  value,
  onChange,
  label,
  errorText = '',
  success = false,
  type = 'text',
  placeholder,
  helperText,
  maxLength,
  prefix,
  suffix,
  rows = 0,
  required = false,
  fullWidth = false,
  disabled = false,
  disableGutters = false,
  disablePadding = false,
  autoFocus = false,
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()
  const theme = useTheme()

  let prefixIconType: string | undefined = prefix?.icon || ''
  if (!Object.prototype.hasOwnProperty.call(Icons, prefixIconType)) {
    prefixIconType = undefined
  }

  let suffixIconType: string | undefined = suffix?.icon || ''
  if (!Object.prototype.hasOwnProperty.call(Icons, suffixIconType)) {
    suffixIconType = undefined
  }

  const InputProps: {
    className: string
    startAdornment?: React.ReactFragment
    endAdornment?: React.ReactFragment
  } = {
    className: classes.inputText
  }

  InputProps.startAdornment =
    prefix?.text || prefix?.icon ? (
      <Fragment>
        <InputAdornment position="start" onClick={prefix.onClick}>
          {prefixIconType
            ? React.createElement(Icons[prefixIconType], {
                style: {
                  color: theme.palette.text.secondary,
                  cursor: prefix.onClick ? 'pointer' : 'default'
                }
              })
            : prefix.text}
        </InputAdornment>
      </Fragment>
    ) : undefined

  InputProps.endAdornment =
    suffix?.text || suffix?.icon ? (
      <Fragment>
        <InputAdornment position="end" onClick={suffix.onClick}>
          {suffixIconType
            ? React.createElement(Icons[suffixIconType], {
                style: {
                  color: theme.palette.text.secondary,
                  cursor: suffix.onClick ? 'pointer' : 'default'
                }
              })
            : suffix.text}
        </InputAdornment>
      </Fragment>
    ) : undefined

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <div
        className={clsx({
          [classes.container]: !disablePadding,
          [classes.disableGutters]: disableGutters && !disablePadding
        })}
      >
        <MaterialTextField
          className={clsx(classes.inputField, {
            [classes.inputSuccess]: success
          })}
          variant="outlined"
          fullWidth={fullWidth}
          name={name}
          autoComplete={autoComplete}
          type={type}
          required={required}
          disabled={disabled}
          autoFocus={autoFocus}
          label={label}
          placeholder={placeholder}
          multiline={rows > 0}
          rows={rows}
          value={value}
          onChange={onChange}
          error={errorText !== ''}
          helperText={
            maxLength ? (
              <Fragment>
                <span
                  className={clsx({
                    [classes.helperTextSuccess]: success && !errorText
                  })}
                >
                  {errorText ? errorText : helperText || ''}
                </span>{' '}
                <span
                  className={clsx({
                    [classes.helperTextSuccess]: success && !errorText
                  })}
                >
                  {value !== undefined
                    ? `${value.length}/${maxLength}`
                    : `0/${maxLength}`}
                </span>
              </Fragment>
            ) : (
              <span
                className={clsx({
                  [classes.helperTextSuccess]: success && !errorText
                })}
              >
                {errorText ? errorText : helperText || ''}
              </span>
            )
          }
          inputProps={{ maxLength: maxLength }}
          InputProps={InputProps}
        />
      </div>
    </Wrapper>
  )
}

export default TextField
