import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: '12px'
  },
  component: {
    width: '100%',
    padding: '4px'
  }
}))

export interface Props {
  /**
   * Array of components
   */
  components?: React.ReactNode[]
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const BottomBar: React.FC<Props> = ({
  components = [],
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <div className={classes.container}>
        {components.map((component, i) => (
          <div key={i} className={classes.component}>
            {component}
          </div>
        ))}
      </div>
    </Wrapper>
  )
}

export default BottomBar
