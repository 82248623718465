import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  powered: {
    fontFamily: '"Roboto" sans-serif',
    textAlign: 'center',
    borderTop: '1px solid rgba(0, 44, 56, 0.12)',
    padding: '5px 0px 8px 0px',
    background: 'white'
  },
  text: {
    fontFamily: '"Roboto" sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    color: 'rgba(0, 44, 56, 0.38)'
  },
  link: {
    fontFamily: '"Roboto" sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    color: 'rgba(0, 44, 56, 0.60)',
    textDecoration: 'none'
  }
}))

export interface Props {
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
}

const Whitelabel: React.FC<Props> = ({ style, className }) => {
  const classes = useStyles()

  return (
    <div style={style} className={className}>
      <div className={classes.powered}>
        <div>
          <Typography component="span" className={classes.text}>
            Compose with{' '}
          </Typography>
          <Typography
            component="a"
            className={classes.link}
            href="https://uniqore.com/welcome"
            target="_blank"
            rel="noopener noreferrer"
          >
            uniqore&trade;
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default Whitelabel
