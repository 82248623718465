import xss from 'xss'

const XssWhiteList = {
  whiteList: {
    //@ts-ignore
    ...xss.whiteList,
    a: ['href', 'target', 'title', 'rel']
  }
}

export default XssWhiteList
