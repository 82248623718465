import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px'
  },
  headline: {
    paddingBottom: '5px'
  },
  text: {
    color: theme.palette.text.primary
  },
  subtitle: {
    color: theme.palette.text.secondary
  }
}))

export interface Props {
  /**
   * What headline to use
   */
  headline?: string
  /**
   * What title to use
   */
  title?: string
  /**
   * What subtitle to use
   */
  subtitle?: string
  /**
   * What body to use
   */
  body?: string
  /**
   * What align to use
   */
  align?: 'left' | 'right' | 'center' | 'justify'
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const Heading: React.FC<Props> = ({
  headline,
  title,
  subtitle,
  body,
  align = 'center',
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <div className={classes.container} style={{ textAlign: align }}>
        {headline ? (
          <Typography
            variant="h5"
            className={clsx(classes.headline, classes.text)}
          >
            {headline}
          </Typography>
        ) : null}
        {title ? (
          <Typography variant="h6" className={classes.text}>
            {title}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography
            variant="subtitle1"
            className={clsx(classes.subtitle, classes.text)}
          >
            {subtitle}
          </Typography>
        ) : null}
        {body ? (
          <Typography variant="body2" className={classes.text}>
            {body}
          </Typography>
        ) : null}
      </div>
    </Wrapper>
  )
}

export default Heading
