import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { default as MaterialContainer } from '@material-ui/core/Container'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles(() => ({
  defaultPadding: {
    padding: '8px 16px'
  }
}))

export interface Props {
  /**
   * @ignore
   */
  children: NonNullable<React.ReactNode>
  /**
   * Determine the max-width of the container.
   * The container width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
  /**
   * Set the max-width to match the min-width of the current breakpoint.
   * This is useful if you'd prefer to design for a fixed set of sizes
   * instead of trying to accommodate a fully fluid viewport.
   * It's fluid by default.
   */
  fixed?: boolean
  /**
   * Sets padding top and bottom to 8px, left and right to 16px.
   */
  defaultPadding?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
}

const Container: React.FC<Props> = ({
  maxWidth = 'sm',
  children,
  fixed = false,
  defaultPadding = false,
  style,
  className
}) => {
  const classes = useStyles()

  let setMaxWidth: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false = false
  if (maxWidth) {
    setMaxWidth = maxWidth
  }

  return (
    <React.Fragment>
      {setMaxWidth ? (
        <MaterialContainer
          classes={{ root: clsx({ [classes.defaultPadding]: defaultPadding }) }}
          className={className}
          style={style}
          maxWidth={maxWidth}
          fixed={fixed}
          disableGutters
        >
          {children}
        </MaterialContainer>
      ) : (
        <div className={clsx({ [classes.defaultPadding]: defaultPadding })}>
          <Wrapper className={className} style={style} maxWidth={false}>
            {children}
          </Wrapper>
        </div>
      )}
    </React.Fragment>
  )
}

export default Container
