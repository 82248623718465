import React, { Fragment } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icons from '../common/Icons'
import { TextFieldPrefixAndSuffix } from '../TextField/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '8px 16px 8px 16px'
  },
  inputField: {
    '& p': {
      display: 'flex',
      justifyContent: 'space-between',
      fontStyle: theme.typography.caption.fontStyle,
      fontWeight: theme.typography.caption.fontWeight,
      fontSize: theme.typography.caption.fontSize,
      lineHeight: theme.typography.caption.lineHeight,
      letterSpacing: theme.typography.caption.letterSpacing
    }
  },
  inputText: {
    fontStyle: theme.typography.body1.fontStyle,
    fontWeight: theme.typography.body1.fontWeight,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    letterSpacing: theme.typography.body1.letterSpacing
  },
  inputSuccess: {
    '& [class*="MuiFormLabel-root"]': {
      color: theme.palette.success.main
    },
    '& [class*="MuiOutlinedInput-root"]': {
      '& fieldset': {
        borderColor: theme.palette.success.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.success.main
      },
      '& [class*="Mui-focused"] fieldset': {
        borderColor: theme.palette.success.main
      }
    }
  },
  helperTextSuccess: {
    color: theme.palette.success.main
  }
}))

export interface SelectOption {
  value: string | number
  label: string | number
}

export interface Props {
  /**
   * Name attribute of the component.
   */
  name?: string
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   */
  autoComplete?: string
  /**
   * The input value. Providing an empty string will select no options.
   * Value must have reference equality with the option in order to be selected.
   */
  value?: string
  /**
   * Callback function fired when a select option is selected.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value`.
   */
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  /**
   * What label to use
   */
  label?: string
  /**
   * What helper text to use. Helper text is displayed below the select component.
   */
  helperText?: string
  /**
   * What error text to use. Error text is displayed below the select component.
   */
  errorText?: string
  /**
   * If 'true', the border of the component is displayed in green color
   */
  success?: boolean
  /**
   * An array of objects. Object has label and value properties.
   */
  options?: SelectOption[]
  /**
   * Prefix is set before the entered text and is an object with text, icon and onClick properties.
   */
  prefix?: TextFieldPrefixAndSuffix
  /**
   * If `true`, the label is displayed as required and the `input` element will be required.
   */
  required?: boolean
  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth?: boolean
  /**
   * If 'true', the component will be disabled
   */
  disabled?: boolean
  /**
   * If 'true, the component's padding will be disabled
   */
  disablePadding?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const Select: React.FC<Props> = ({
  name,
  autoComplete,
  value = '',
  onChange,
  label,
  helperText,
  errorText = '',
  success = false,
  options = [],
  prefix,
  required = false,
  fullWidth = true,
  disabled = false,
  disablePadding = false,
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()
  const theme = useTheme()

  let prefixIconType: string | undefined = prefix?.icon || ''
  if (!Object.prototype.hasOwnProperty.call(Icons, prefixIconType)) {
    prefixIconType = undefined
  }

  const InputProps: {
    className: string
    startAdornment?: React.ReactFragment
    endAdornment?: React.ReactFragment
  } = {
    className: classes.inputText
  }

  InputProps.startAdornment =
    prefix?.text || prefix?.icon ? (
      <Fragment>
        <InputAdornment position="start" onClick={prefix.onClick}>
          {prefixIconType
            ? React.createElement(Icons[prefixIconType], {
                style: {
                  color: theme.palette.text.secondary,
                  cursor: prefix.onClick ? 'pointer' : 'default'
                }
              })
            : prefix.text}
        </InputAdornment>
      </Fragment>
    ) : undefined

  InputProps.endAdornment = undefined

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <div className={clsx({ [classes.container]: !disablePadding })}>
        <TextField
          className={clsx(classes.inputField, {
            [classes.inputSuccess]: success
          })}
          variant="outlined"
          select
          fullWidth={fullWidth}
          name={name}
          autoComplete={autoComplete}
          required={required}
          disabled={disabled}
          label={label}
          value={value}
          onChange={onChange}
          error={errorText !== ''}
          helperText={
            <span
              className={clsx({
                [classes.helperTextSuccess]: success && !errorText
              })}
            >
              {errorText ? errorText : helperText || ''}
            </span>
          }
          InputProps={InputProps}
        >
          {options.map((option) => {
            return option.value && option.label ? (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ) : null
          })}
        </TextField>
      </div>
    </Wrapper>
  )
}

export default Select
