import { createMuiTheme } from '@material-ui/core/styles'

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#43A047'
    },
    secondary: {
      main: '#002C38'
    },
    text: {
      primary: 'rgba(0, 44, 56, 0.87)',
      secondary: 'rgba(0, 44, 56, 0.6)',
      disabled: 'rgba(0, 44, 56, 0.38)'
    }
  },
  typography: {
    h1: {
      fontStyle: 'normal',
      fontWeight: 300
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 300
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 700
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 500
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 500
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    caption: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    button: {
      fontStyle: 'normal',
      fontWeight: 500
    },
    overline: {
      fontStyle: 'normal',
      fontWeight: 500
    }
  }
})

export default defaultTheme
