import { createMuiTheme } from '@material-ui/core/styles'

const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#43A047'
    },
    secondary: {
      main: '#002C38'
    },
    text: {
      primary: 'rgba(255, 255, 255, 1)',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)'
    },
    type: 'dark'
  },
  typography: {
    h1: {
      fontStyle: 'normal',
      fontWeight: 300
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 300
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 700
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 500
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 500
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    caption: {
      fontStyle: 'normal',
      fontWeight: 400
    },
    button: {
      fontStyle: 'normal',
      fontWeight: 500
    },
    overline: {
      fontStyle: 'normal',
      fontWeight: 500
    }
  }
})

export default darkTheme
