import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import { ReactComponent as SpinnerLogo } from '../../assets/spinners/spinner-logo-dark.svg'
import { ReactComponent as SpinnerLogoLight } from '../../assets/spinners/spinner-logo-dark-60.svg'
import { ReactComponent as SpinnerLogoWhite } from '../../assets/spinners/spinner-logo-white.svg'
import { ReactComponent as SpinnerLogoWhiteLight } from '../../assets/spinners/spinner-logo-white-60.svg'

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: 'relative',
    zIndex: theme.zIndex.tooltip + 1
  },
  backdrop: {
    zIndex: theme.zIndex.tooltip
  }
}))

export interface Props {
  /**
   * The variant to use.
   */
  variant?: 'default' | 'defaultLight' | 'white' | 'whiteLight'
  /**
   * If 'true', adds backdrop
   */
  backdrop?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
}

const Spinner: React.FC<Props> = ({
  variant = 'default',
  backdrop = false,
  style,
  className
}) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div style={style} className={className}>
        {variant === 'defaultLight' ? (
          <SpinnerLogoLight className={classes.spinner} />
        ) : variant === 'white' ? (
          <SpinnerLogoWhite className={classes.spinner} />
        ) : variant === 'whiteLight' ? (
          <SpinnerLogoWhiteLight className={classes.spinner} />
        ) : (
          <SpinnerLogo className={classes.spinner} />
        )}
      </div>
      {backdrop && <Backdrop className={classes.backdrop} open />}
    </React.Fragment>
  )
}

export default Spinner
