import React, { useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import { default as MaterialListItem } from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Icons from '../common/Icons'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.text.primary
  },
  secondary: {
    display: 'block',
    color: theme.palette.text.secondary
  },
  listItemImageRectangle: {
    paddingLeft: '0px'
  },
  imageBorder: {
    border: '1px solid rgba(0, 44, 56, 0.12)'
  },
  imageContainer: {
    height: '56px',
    width: '56px',
    display: 'flex',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto',
      display: 'block'
    }
  },
  imageRectangle: {
    width: '100px',
    display: 'flex'
  },
  icon: {
    color: theme.palette.text.primary
  },
  iconPrimaryColor: {
    color: theme.palette.primary.main
  },
  metaText: {
    color: theme.palette.text.secondary
  },
  metaTextPrimaryColor: {
    color: theme.palette.primary.main
  },
  disableRightGutter: {
    right: '0px'
  },
  noPointerOnIcon: {
    cursor: 'default',
    '&:hover': {
      background: 'none'
    }
  },
  showPointer: {
    cursor: 'pointer'
  },
  metaTextClickable: {
    textDecoration: 'underline'
  },
  avatar: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.palette.secondary.main
  },
  actionLabel: {
    color: theme.palette.text.primary
  }
}))

export interface ListTypeAvatar {
  image?: string
  text?: string
  color?: string
  backgroundColor?: string
}

export interface ListAction {
  label?: string
  icon?: string
  color?: string
  onClick?: React.MouseEventHandler
}

export interface Props {
  /**
   * What text to use
   */
  text?: string
  /**
   * Array of strings. Subtitles are shown as rows below the label.
   */
  subtitles?: string[]
  /**
   * An object with image, text, color and backgroundColor properties.
   */
  avatar?: ListTypeAvatar
  /**
   * Image as string
   */
  image?: string
  /**
   * Adds border around the image
   */
  imageBorder?: boolean
  /**
   * If 'true', image area is a rectangle instead of a square
   */
  imageRectangle?: boolean
  /**
   * Icon is shown at the left side of the component. Icon uses Material UI icon names.
   */
  icon?: string
  /**
   * Metatext is shown at the right side of the component.
   */
  metaText?: string
  /**
   * Metaicon is shown at the right side of the component. Icon uses Material UI icon names.
   */
  metaIcon?: string
  /**
   * If 'true', metaText and metaIcon color is set to theme's primary color
   */
  hightlightMeta?: boolean
  /**
   * An array of objects with optional label, icon and onClick properties. If more than one object is given, menu is used.
   */
  actions?: ListAction[]
  /**
   * Component's onClick event
   */
  onClick?: React.MouseEventHandler
  /**
   * If 'true, the left and right padding is removed
   */
  disableGutters?: boolean
  /**
   * If 'true', compact vertical padding designed for keyboard and mouse input will be used.
   */
  dense?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const ListItem: React.FC<Props> = ({
  text,
  subtitles = [],
  avatar,
  image,
  imageBorder = false,
  imageRectangle = false,
  icon = '',
  metaText,
  metaIcon = '',
  hightlightMeta = false,
  actions = [],
  onClick,
  disableGutters = false,
  dense = false,
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  let iconType: string | undefined = icon
  if (!Object.prototype.hasOwnProperty.call(Icons, icon)) {
    iconType = undefined
  }

  let metaIconType: string | undefined = metaIcon
  if (!Object.prototype.hasOwnProperty.call(Icons, metaIcon)) {
    metaIconType = undefined
  }

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <List disablePadding dense={dense}>
        <MaterialListItem
          className={clsx({
            [classes.listItemImageRectangle]: imageRectangle && image
          })}
          disableGutters={disableGutters}
          alignItems={
            iconType && !image
              ? 'center'
              : subtitles.length <= 1
              ? 'center'
              : 'flex-start'
          }
        >
          {image ? (
            <div
              onClick={onClick}
              style={{ minWidth: imageRectangle ? '116px' : '72px' }}
            >
              <ListItemAvatar
                style={{ cursor: onClick ? 'pointer' : 'default' }}
                className={clsx(classes.imageContainer, {
                  [classes.imageRectangle]: imageRectangle,
                  [classes.imageBorder]: imageBorder
                })}
              >
                <img src={image} alt="product" />
              </ListItemAvatar>
            </div>
          ) : avatar ? (
            <div onClick={onClick} style={{ minWidth: '56px' }}>
              <ListItemAvatar
                style={{ cursor: onClick ? 'pointer' : 'default' }}
              >
                {avatar.image ? (
                  <Avatar alt="Person" src={avatar.image} />
                ) : (
                  <Avatar
                    aria-label="avatar"
                    className={classes.avatar}
                    style={{
                      color: avatar.color,
                      backgroundColor: avatar.backgroundColor
                    }}
                  >
                    {avatar.text}
                  </Avatar>
                )}
              </ListItemAvatar>
            </div>
          ) : iconType ? (
            <div
              style={{
                height: subtitles.length <= 1 ? '23px' : '',
                cursor: onClick ? 'pointer' : 'default'
              }}
              onClick={onClick}
            >
              <ListItemIcon className={classes.icon}>
                {React.createElement(Icons[iconType])}
              </ListItemIcon>
            </div>
          ) : null}
          <ListItemText
            className={clsx({
              [classes.showPointer]: onClick
            })}
            onClick={onClick}
            primary={
              <React.Fragment>
                {text ? (
                  <Typography variant="subtitle1" className={classes.primary}>
                    {text}
                  </Typography>
                ) : null}
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                {subtitles.map((subtitle, i) => (
                  <Typography
                    key={i}
                    className={classes.secondary}
                    variant="body2"
                    component="span"
                  >
                    {subtitle}
                  </Typography>
                ))}
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction
            className={clsx({
              [classes.disableRightGutter]: disableGutters
            })}
          >
            {actions.length !== 1 && (
              <React.Fragment>
                {metaIconType ? (
                  <IconButton
                    onClick={actions.length > 0 ? handleClick : onClick}
                    disableRipple={!onClick}
                    className={clsx({
                      [classes.icon]: !hightlightMeta,
                      [classes.iconPrimaryColor]: hightlightMeta,
                      [classes.noPointerOnIcon]:
                        !onClick && actions.length === 0
                    })}
                    edge="end"
                  >
                    {React.createElement(Icons[metaIconType])}
                  </IconButton>
                ) : (
                  <Typography
                    onClick={actions.length > 0 ? handleClick : onClick}
                    variant="caption"
                    className={clsx({
                      [classes.metaText]: !hightlightMeta,
                      [classes.metaTextPrimaryColor]: hightlightMeta,
                      [classes.showPointer]: onClick || actions.length > 0,
                      [classes.metaTextClickable]: onClick
                    })}
                  >
                    {metaText}
                  </Typography>
                )}
              </React.Fragment>
            )}
            {actions.length === 1 && (
              <React.Fragment>
                {actions[0].icon ? (
                  <IconButton
                    onClick={actions[0].onClick}
                    disableRipple={!actions[0].onClick}
                    style={{ color: actions[0].color }}
                    className={clsx({
                      [classes.icon]: !hightlightMeta,
                      [classes.iconPrimaryColor]: hightlightMeta
                    })}
                    edge="end"
                  >
                    {React.createElement(Icons[actions[0].icon])}
                  </IconButton>
                ) : (
                  <Typography
                    onClick={actions[0].onClick}
                    variant="caption"
                    style={{
                      cursor: 'pointer',
                      color: actions[0].color
                    }}
                    className={clsx({
                      [classes.metaText]: !hightlightMeta,
                      [classes.metaTextPrimaryColor]: hightlightMeta
                    })}
                  >
                    {actions[0].label}
                  </Typography>
                )}
              </React.Fragment>
            )}
            {actions.length > 1 && (
              <React.Fragment>
                {!metaIconType && !metaText && (
                  <IconButton
                    onClick={handleClick}
                    disableRipple={!onClick}
                    className={clsx({
                      [classes.icon]: !hightlightMeta,
                      [classes.iconPrimaryColor]: hightlightMeta
                    })}
                    edge="end"
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {actions.map((action, i) => {
                    let icon: string | undefined = action.icon || ''
                    if (!Object.prototype.hasOwnProperty.call(Icons, icon)) {
                      icon = undefined
                    }
                    return (
                      <MenuItem key={i} onClick={action.onClick}>
                        {icon && (
                          <ListItemIcon
                            style={{ color: action.color }}
                            className={classes.icon}
                          >
                            {React.createElement(Icons[icon])}
                          </ListItemIcon>
                        )}
                        <Typography
                          variant="body2"
                          className={classes.actionLabel}
                          style={{
                            color: action.color
                          }}
                          noWrap
                        >
                          {action.label}
                        </Typography>
                      </MenuItem>
                    )
                  })}
                </Menu>
              </React.Fragment>
            )}
          </ListItemSecondaryAction>
        </MaterialListItem>
      </List>
    </Wrapper>
  )
}

export default ListItem
