import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import fiLocale from 'date-fns/locale/fi'
import locale from 'date-fns/locale/en-US'
import DateFnsUtils from '@date-io/date-fns'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '8px 16px 8px 16px'
  },
  calendar: {
    '& [class*="MuiPickersStaticWrapper-staticWrapperRoot"]': {
      overflow: 'visible'
    },
    '& [class*="MuiPickersBasePicker-pickerView"]': {
      maxWidth: '100%',
      overflowX: 'visible'
    },
    '& [class*="MuiPickersCalendarHeader-daysHeader"]': {
      justifyContent: 'space-between',
      width: '100%',
      '@media (min-width: 425px)': {
        width: '90%',
        margin: '0 auto'
      }
    },
    '& [class*="MuiPickersCalendar-week"]': {
      justifyContent: 'space-between',
      paddingBottom: '24px'
    },
    '& [class*="MuiPickersCalendar-transitionContainer"]': {
      minHeight: '360px',
      width: '100%',
      '@media (min-width: 425px)': {
        width: '90%',
        margin: '0 auto'
      }
    }
  },
  datepicker: {
    position: 'relative',
    '& [class*="MuiOutlinedInput-adornedEnd"]': {
      paddingRight: '0px'
    },
    '& [class*="MuiIconButton-root"]': {
      color: theme.palette.text.secondary
    }
  },
  dialog: {
    '& [class*="MuiBackdrop-root"]': {
      position: 'absolute'
    }
  },
  toolbar: {
    '& > div:first-child': {
      //width: '325px'
    }
  },
  dateStyle: {
    '& [class*="MuiPickersDay-daySelected"]': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`
    },
    '& [class*="MuiPickersDay-current"]': {
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '50%',
      backgroundColor: '#FFFFFF',
      color: theme.palette.text.primary
    }
  },
  availableDate: {
    '& > button:first-child': {
      borderRadius: '50%',
      backgroundColor: '#EAEAF0',
      color: theme.palette.secondary.main
    }
  }
}))

export interface Props {
  /**
   * Default selected date
   */
  date?: Date
  /**
   * Function to change the selected date
   */
  onChange: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => void
  /**
   * Array of Date objects. Available dates are shown with grey background in calendar.
   */
  availableDates?: Date[]
  /**
   * The variant to use.
   */
  variant?: 'dialog' | 'inline' | 'static'
  /**
   * Sets the language of the component
   */
  language?: 'en' | 'fi'
  /**
   * If 'true', past will be disabled
   */
  disablePast?: boolean
  /**
   * If 'true', hides toolbar and show only date/time views
   */
  disableToolbar?: boolean
  /**
   * Label for textfield
   */
  label?: string
  /**
   * Ok label text
   */
  okLabel?: string
  /**
   * Cancel label text
   */
  cancelLabel?: string
  /**
   * If 'true, padding will be disabled
   */
  disablePadding?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const DatePicker: React.FC<Props> = ({
  date,
  onChange,
  availableDates = [],
  variant = 'static',
  language = 'fi',
  disablePast = false,
  disableToolbar = false,
  label,
  okLabel,
  cancelLabel,
  disablePadding = false,
  className,
  style,
  maxWidth
}) => {
  const classes = useStyles()

  let setLocale = {}
  if (fiLocale && fiLocale.options && language === 'fi') {
    fiLocale.options.weekStartsOn = 1
    setLocale = fiLocale
  }
  if (locale && locale.options && language === 'en') {
    locale.options.weekStartsOn = 1
    setLocale = locale
  }

  return (
    <Wrapper className={className} style={style} maxWidth={maxWidth}>
      <div
        className={clsx({
          [classes.container]: !disablePadding,
          [classes.toolbar]: variant === 'static',
          [classes.calendar]: variant === 'static'
        })}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={setLocale}>
          <KeyboardDatePicker
            className={classes.datepicker}
            allowKeyboardControl
            autoOk
            fullWidth
            disableToolbar={disableToolbar}
            disablePast={disablePast}
            okLabel={okLabel ? okLabel : 'OK'}
            cancelLabel={
              cancelLabel
                ? cancelLabel
                : language === 'fi'
                ? 'PERUUTA'
                : 'CANCEL'
            }
            DialogProps={{
              style: { position: 'absolute' },
              className: classes.dialog
            }}
            format="dd/MM/yyyy"
            inputVariant="outlined"
            value={date}
            onChange={onChange}
            variant={variant}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            label={label}
            renderDay={(day, _date, _isInCurrentMonth, dayComponent) => {
              const today = new Date()
              let isAvailable = false
              if (day) {
                availableDates.forEach((date) => {
                  if (
                    date.getDate() === day.getDate() &&
                    date.getMonth() === day.getMonth() &&
                    date.getFullYear() === day.getFullYear()
                  ) {
                    isAvailable = true
                  }
                  if (
                    date.getFullYear() === today.getFullYear() &&
                    date.getMonth() === today.getMonth()
                  ) {
                    if (date.getDate() < today.getDate()) {
                      isAvailable = false
                    }
                  }
                })
              }
              return (
                <div
                  className={clsx(classes.dateStyle, {
                    [classes.availableDate]: isAvailable
                  })}
                >
                  {dayComponent}
                </div>
              )
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    </Wrapper>
  )
}

export default DatePicker
