import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Wrapper from '../common/Wrapper'
import Heading from '../Heading/Heading'
import OTPInput from './OTPInput'

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '16px'
  },
  input: {
    maxWidth: 44,
    marginRight: theme.spacing(1),
    minHeight: 54,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    fontSize: 30,
    border: 'none',
    boxShadow:
      '0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)',
    borderRadius: 4
  },
  inputWithError: {
    boxShadow: `0px 0px 2px ${theme.palette.error.main}, 0px 0px 2px ${theme.palette.error.main}, 0px 1px 3px ${theme.palette.error.main}`
  },
  verificationInputs: {
    textAlign: 'center'
  }
}))

export interface Props {
  /**
   * Called when all input fields are filled
   */
  onSubmit?: (verificationCode: string) => void
  /**
   * What headline to use
   */
  headline?: string
  /**
   * What subtitle to use
   */
  subtitle?: string
  /**
   * If 'true' password is visible
   */
  showPassword?: boolean
  /**
   * if 'true', numpads are visible
   */
  showNumpad?: boolean
  /**
   * Turn on/off autofocus on current input
   */
  autoFocus?: boolean
  /**
   * If 'true', only numbers are used in inputs
   */
  isNumberInput?: boolean
  /**
   * Length of verification input
   */
  length?: number
  /**
   * Message to a user if verification was failed
   */
  errorText?: string
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const Verification: React.FC<Props> = ({
  onSubmit,
  headline,
  subtitle,
  showPassword = false,
  showNumpad = false,
  autoFocus = false,
  isNumberInput = true,
  length = 6,
  errorText = '',
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()

  const handleSubmit = (otp: string) => {
    if (onSubmit) {
      onSubmit(otp)
    }
  }

  return (
    <Wrapper maxWidth={maxWidth} style={style} className={className}>
      <Heading
        className={classes.heading}
        align="center"
        headline={headline}
        body={subtitle}
      />
      <OTPInput
        className={classes.verificationInputs}
        autoFocus={autoFocus}
        isNumberInput={isNumberInput}
        length={length}
        inputClassName={
          errorText
            ? `${classes.input} ${classes.inputWithError}`
            : classes.input
        }
        onSubmit={(otp) => handleSubmit(otp)}
        showPassword={showPassword}
        showNumpad={showNumpad}
        errorText={errorText}
      />
    </Wrapper>
  )
}

export default Verification
