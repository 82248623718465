import React from 'react'
import xss from 'xss'
import XssWhiteList from '../common/XssWhiteList'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Icons from '../common/Icons'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '8px 16px'
  },
  listItemIcon: {
    color: theme.palette.text.secondary,
    minWidth: '0',
    paddingRight: '21px',
    paddingTop: '3px'
  },
  alignLeft: {
    justifyContent: 'start'
  },
  alignCenter: {
    justifyContent: 'center'
  },
  alignRight: {
    justifyContent: 'flex-end'
  },
  textAlignLeft: {
    textAlign: 'left'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  textAlignRight: {
    textAlign: 'right'
  },
  text: {
    color: theme.palette.text.secondary,
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  linksContainer: {
    paddingTop: '8px',
    display: 'flex'
  },
  link: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.033em',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    color: theme.palette.text.secondary
  },
  linksAlignCenter: {
    justifyContent: 'space-evenly'
  },
  linksAlignRight: {
    justifyContent: 'flex-end'
  },
  linksAlignLeftRight: {
    paddingRight: '16px'
  }
}))

export interface SmallPrintLink {
  text?: string
  url?: string
}

export interface Props {
  /**
   *  Icon uses Material UI icon names.
   */
  icon?: string
  /**
   * The text content.
   */
  text?: string
  /**
   * The text content. Supports html.
   */
  htmlText?: string
  /**
   * What align to use
   */
  align?: 'left' | 'right' | 'center'
  /**
   * An array of objects. Object has optional text and url properties.
   */
  links?: SmallPrintLink[]
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const SmallPrint: React.FC<Props> = ({
  icon = '',
  text,
  htmlText,
  align = 'center',
  links = [],
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()

  let iconType: string | undefined = icon
  if (!Object.prototype.hasOwnProperty.call(Icons, icon)) {
    iconType = undefined
  }

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <div className={classes.container}>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          className={clsx({
            [classes.alignLeft]: align === 'left',
            [classes.alignCenter]: align === 'center',
            [classes.alignRight]: align === 'right'
          })}
        >
          {iconType ? (
            <div className={classes.listItemIcon}>
              {React.createElement(Icons[iconType])}
            </div>
          ) : null}
          <div
            className={clsx({
              [classes.textAlignLeft]: align === 'left',
              [classes.textAlignCenter]: align === 'center',
              [classes.textAlignRight]: align === 'right'
            })}
          >
            {htmlText ? (
              <Typography
                variant="caption"
                className={classes.text}
                dangerouslySetInnerHTML={{
                  __html: xss(htmlText, XssWhiteList)
                }}
              />
            ) : null}
            {text && !htmlText ? (
              <Typography variant="caption" className={classes.text}>
                {text}
              </Typography>
            ) : null}
          </div>
        </div>
        {links.length > 0 && (
          <div
            className={clsx(classes.linksContainer, {
              [classes.linksAlignCenter]: align === 'center',
              [classes.linksAlignRight]: align === 'right'
            })}
          >
            {links.map((link, i) => (
              <div key={i}>
                <Typography
                  component="a"
                  className={clsx(classes.link, {
                    [classes.linksAlignLeftRight]:
                      align === 'left' || align === 'right'
                  })}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.text}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default SmallPrint
