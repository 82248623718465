import React from 'react'
import { default as BottomNavigationMaterial } from '@material-ui/core/BottomNavigation'
import { default as BottomNavigationActionMaterial } from '@material-ui/core/BottomNavigationAction'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import Icons from '../common/Icons'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '56px',
    transition: 'none !important'
  },
  action: {
    maxWidth: '100% !important',
    transition: 'none !important'
  },
  // @ts-ignore
  selected: ({ color }) => ({
    // @ts-ignore
    fontSize: `${theme.typography.caption.fontSize} !important`,
    color:
      color === 'primary'
        ? // @ts-ignore
          `${theme.palette.primary.main} !important`
        : // @ts-ignore
          `${theme.palette.secondary.main} !important`
  }),
  label: {
    transition: 'none !important'
  }
}))

export interface BottomNavigationAction {
  label?: string
  value?: string | number
  icon?: string
}

export interface Props {
  /**
   * The value of the currently selected action.
   */
  value?: string | number
  /**
   * Callback fired when the value changes.
   *
   * @param {object} event The event source of the callback.
   * @param {any} value We default to the index of the child.
   */
  onChange?: (event: object, value: any) => void
  /**
   * The color of the selected tab based on the theme that is used
   */
  color?: 'primary' | 'secondary'
  /**
   * An array of objects. Object has optional label, value and icon properties.
   */
  actions?: BottomNavigationAction[]
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const BottomNavigation: React.FC<Props> = ({
  value,
  onChange,
  color = 'secondary',
  actions = [],
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles({ color })

  return (
    <Wrapper style={style} className={className}>
      <div style={{ borderTop: '1px solid rgba(0, 44, 56, 0.12)' }}>
        <Wrapper maxWidth={maxWidth}>
          <BottomNavigationMaterial
            showLabels
            value={value}
            onChange={onChange}
            className={classes.container}
          >
            {actions.map((action, i) => {
              let icon: string | undefined = action.icon || ''
              if (!Object.prototype.hasOwnProperty.call(Icons, icon)) {
                icon = undefined
              }
              return (
                <BottomNavigationActionMaterial
                  key={i}
                  classes={{ selected: classes.selected }}
                  className={classes.action}
                  label={
                    <Typography variant="caption" className={classes.label}>
                      {action.label}
                    </Typography>
                  }
                  value={action.value}
                  icon={icon ? React.createElement(Icons[icon]) : null}
                />
              )
            })}
          </BottomNavigationMaterial>
        </Wrapper>
      </div>
    </Wrapper>
  )
}

export default BottomNavigation
