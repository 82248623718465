import React from 'react'
import xss from 'xss'
import XssWhiteList from '../common/XssWhiteList'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { default as MaterialSwitch } from '@material-ui/core/Switch'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingRight: '64px'
  },
  primary: {
    color: theme.palette.text.primary
  },
  secondary: {
    display: 'block',
    color: theme.palette.text.secondary
  },
  disableRightGutter: {
    right: '0px'
  }
}))

export interface Props {
  /**
   * The value of the component
   */
  value?: string | number
  /**
   * The name of the component
   */
  name?: string
  /**
   * The color of the checked switch based on the theme that is used
   */
  color?: 'primary' | 'secondary'
  /**
   * If `true`, the component is checked.
   */
  checked?: boolean
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * What label to use
   */
  label?: string
  /**
   * What label to use. Supports html
   */
  htmlLabel?: string
  /**
   * Array of strings. Subtitles are shown as rows below the label
   */
  subtitles?: string[]
  /**
   * If 'true', the component will be disabled
   */
  disabled?: boolean
  /**
   * If 'true', the left and right padding is removed
   */
  disableGutters?: boolean
  /**
   * If 'true', compact vertical padding designed for keyboard and mouse input will be used.
   */
  dense?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const Switch: React.FC<Props> = ({
  value = '',
  name,
  color = 'secondary',
  checked = false,
  onChange,
  label,
  htmlLabel,
  subtitles = [],
  disabled = false,
  disableGutters = false,
  dense = false,
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <List disablePadding dense={dense}>
        <ListItem className={classes.listItem} disableGutters={disableGutters}>
          <ListItemText
            primary={
              <React.Fragment>
                {htmlLabel ? (
                  <Typography
                    variant="subtitle1"
                    className={classes.primary}
                    dangerouslySetInnerHTML={{
                      __html: xss(htmlLabel, XssWhiteList)
                    }}
                  />
                ) : null}
                {label && !htmlLabel ? (
                  <Typography variant="subtitle1" className={classes.primary}>
                    {label}
                  </Typography>
                ) : null}
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                {subtitles.map((subtitle, i) => (
                  <Typography
                    key={i}
                    className={classes.secondary}
                    variant="body2"
                    component="span"
                  >
                    {subtitle}
                  </Typography>
                ))}
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction
            className={clsx({ [classes.disableRightGutter]: disableGutters })}
          >
            <MaterialSwitch
              edge="end"
              color={color}
              disabled={disabled}
              value={value}
              name={name}
              checked={checked}
              onChange={onChange}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Wrapper>
  )
}

export default Switch
