import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as ErrorSvg } from '../../assets/errors/errorSVG.svg'

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    height: '100%',
    width: '100%'
  },
  main: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '& [class*="MuiTypography-h5"]': {
      marginTop: '10px',
      marginBottom: '10px'
    },
    '& #link': {
      lineHeight: '21px',
      fontSize: '14px'
    }
  },
  bordered: {
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',
    width: '332px',
    minHeight: '108px',
    height: 'auto',
    paddingLeft: '16px',
    paddingRight: '16px',
    opacity: '0.7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  }
}))

export type Link = {
  url?: string
  text?: string
}

export interface Props {
  /**
   * What image to use. Can be a string or a React Node.
   */
  image?: string | React.ReactNode
  /**
   * If 'true', the default image is not shown.
   */
  disableDefaultImage?: boolean
  /**
   * The text color.
   */
  color?: string
  /**
   * The background color.
   */
  backgroundColor?: string
  /**
   * What text to use.
   */
  text?: string
  /**
   * The error text.
   */
  errorText?: string
  /**
   * The error type (e.x. 404: page not found!).
   */
  errorType?: string
  /**
   * An object with optional text and url properties.
   */
  link?: Link
}

interface ErrorPageSubComponents {
  Status403: React.FC<Props>
  Status404: React.FC<Props>
  Status500: React.FC<Props>
}

const ErrorPage: React.FC<Props> & ErrorPageSubComponents = ({
  image,
  disableDefaultImage = false,
  color = '#fff',
  backgroundColor = '#002C38',
  text,
  errorText,
  errorType,
  link
}) => {
  const classes = useStyles()

  return (
    <div>
      <div
        className={classes.container}
        style={{ backgroundColor: backgroundColor }}
      >
        <div className={classes.main}>
          {image ? (
            typeof image === 'string' || image instanceof String ? (
              <img
                style={{ maxWidth: '100%' }}
                src={image as string}
                alt="status"
              />
            ) : (
              <div>{image}</div>
            )
          ) : !disableDefaultImage ? (
            <ErrorSvg />
          ) : (
            ''
          )}
          <div style={{ color: color }}>
            <Typography variant="h5">{text}</Typography>
            {errorText ? (
              <div
                className={classes.bordered}
                style={{
                  border: `1px ${color} solid`
                }}
              >
                {errorText ? (
                  <Typography variant="body2" gutterBottom>
                    {errorText}
                  </Typography>
                ) : null}
                {errorType ? (
                  <Typography variant="body2" gutterBottom>
                    {errorType}
                  </Typography>
                ) : null}
              </div>
            ) : null}
            {link ? (
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: color }}
              >
                <Typography variant="body2" gutterBottom>
                  {link.text}
                </Typography>
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const Status403: React.FC<Props> = ({
  image,
  disableDefaultImage = false,
  color = '#fff',
  backgroundColor = '#002C38',
  text,
  errorText,
  errorType,
  link
}) => (
  <ErrorPage
    image={image}
    disableDefaultImage={disableDefaultImage}
    color={color}
    backgroundColor={backgroundColor}
    text={text || 'Oops! Something went wrong.'}
    errorText={
      errorText ||
      'If you can reach out to our support, please tell them following:'
    }
    errorType={errorType || '403: Forbidden'}
    link={link}
  />
)
ErrorPage.Status403 = Status403

const Status404: React.FC<Props> = ({
  image,
  disableDefaultImage = false,
  color = '#fff',
  backgroundColor = '#002C38',
  text,
  errorText,
  errorType,
  link
}) => (
  <ErrorPage
    image={image}
    disableDefaultImage={disableDefaultImage}
    color={color}
    backgroundColor={backgroundColor}
    text={text || 'Oops! Something went wrong.'}
    errorText={
      errorText ||
      'If you can reach out to our support, please tell them following:'
    }
    errorType={errorType || '404: Page not found'}
    link={link}
  />
)
ErrorPage.Status404 = Status404

const Status500: React.FC<Props> = ({
  image,
  disableDefaultImage = false,
  color = '#fff',
  backgroundColor = '#002C38',
  text,
  errorText,
  errorType,
  link
}) => (
  <ErrorPage
    image={image}
    disableDefaultImage={disableDefaultImage}
    color={color}
    backgroundColor={backgroundColor}
    text={text || 'Oops! Something went wrong.'}
    errorText={
      errorText ||
      'If you can reach out to our support, please tell them following:'
    }
    errorType={errorType || '500: Unexpected error'}
    link={link}
  />
)
ErrorPage.Status500 = Status500

export default ErrorPage
